body {
    background: linear-gradient(0deg, rgba(9,9,121,1) 0%, rgba(0,212,255,1) 100%);
    min-height: 100vh;
    position: relative;
}

h1 {
    color: white;
    font-family: report, sans-serif;
    font-weight: 600;
    font-size: 64px;
    margin: 40vh 0 0;
    display: block;
    text-align: center;
    text-transform: lowercase;

    span {
        font-family: ivypresto-display;
        font-weight: 400;
        font-size: 22px;
        text-transform: none;
        border: 1px solid white;
        display: inline-block;
        position: relative;
        bottom: 7px;
        left: 3px;
        border-radius: 1px;
        line-height: 22px;
        padding: 0 4px;
    }
}

h2 {
    color: white;
    font-family: ivypresto-display;
    font-weight: 100;
    font-size: 24px;
    margin: 0;
    display: block;
    text-align: center;
}

h3 {
    color: white;
    font-family: ivypresto-display;
    font-weight: 300;
    font-size: 16px;
    margin: 0;
    display: block;
    text-align: center;
    text-transform: uppercase;
}

footer {
    position: absolute;
    bottom: 0;
    display: block;
    padding-bottom: 10px;
    width: 100%;
    text-align: center;
    color: white;
    font-family: sofia-pro;
    font-size: 14px;
    font-weight: 200;
    text-transform: uppercase;

    span {
        opacity: .3;
    }
}
